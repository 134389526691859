import { render, staticRenderFns } from "./DispatchReservationSidebar.vue?vue&type=template&id=00472c2e&scoped=true"
import script from "./DispatchReservationSidebar.vue?vue&type=script&lang=js"
export * from "./DispatchReservationSidebar.vue?vue&type=script&lang=js"
import style0 from "./DispatchReservationSidebar.vue?vue&type=style&index=0&id=00472c2e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00472c2e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VFlex,VLayout,VProgressCircular,VTooltip})
